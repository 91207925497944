<template>
  <div>
    <h1>Details registratie {{ getFormattedDate }} {{ getFormattedStartTime }} - {{ getFormattedStopTime }} </h1>
    <v-btn v-if="!isOtherUserView" text color="grey" class="text-none pl-0" @click="navigateBack">
      <v-icon color="grey" left>mdi-chevron-left</v-icon>
      Terug naar dagoverzicht
    </v-btn>

    <v-container>
      <v-row> 
        <v-col cols="12" md="6">
          <BasicInformationCard :promises.sync="promises" title="Start locatie"> 
            <template v-slot:content>
              <span v-if="workClocking.clockingStartLocation" class="pl-3">{{ workClocking.clockingStartLocation?.city }} om {{ getFormattedStartTime }}</span>
              <!-- Start map -->
              <GoogleMapsFrame :mapsURL="getGoogleMapsUrlByCoordinates(workClocking.clockingStartLocation?.latitude, workClocking.clockingStartLocation?.longitude)"
                unavailableMessage="Er is geen startlocatie beschikbaar"/>
            </template>
          </BasicInformationCard>
        </v-col>

        <v-col cols="12" md="6">
          <BasicInformationCard :promises.sync="promises" title="Stop locatie" > 
            <template v-slot:content>
              <span v-if="workClocking.clockingStopLocation" class="pl-3">{{ workClocking.clockingStopLocation?.city }} om {{ getFormattedStopTime }}</span>
              <GoogleMapsFrame :mapsURL="getGoogleMapsUrlByCoordinates(workClocking.clockingStopLocation?.latitude, workClocking.clockingStopLocation?.longitude)"
                unavailableMessage="Er is geen stoplocatie beschikbaar"/>
            </template>
          </BasicInformationCard>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import BasicInformationCard from "@/components/shared/BasicInformationCard.vue"
import { format } from "date-fns"
import { getGoogleMapsUrlByCoordinates } from "@/shared/utils/requestUtils"
import { parseStringToDate, applyDateFormat } from "@/shared/utils/dateUtils"
import ClockingType from "@/shared/constants/ClockingType"
import routeNames from "@/router/RouteNames"
import GoogleMapsFrame from "@/components/shared/GoogleMapsFrame.vue"
import { DetailedTimeStamp } from "@/shared/types/DetailedTimeStamp";

export default {
  name: "ClockingDetailView",
  components: {
    BasicInformationCard,
    GoogleMapsFrame,
  },
  props: {
    clockingId: {
      default: null,
      required: true,
      type: String
    },
    date: {
      default: null,
      required: true,
      type: String
    },
    userId: {
      default: null,
      required: false,
      type: String
    }
  },
  data() {
    return {
      promises: [
        this.fetchWorkClocking()
      ],
      workClocking: {
        id: this.clockingId,
        userId: null,
        startTime: null,
        endTime: null,
        comment: null,
        breakTime: null,
        day: this.date,
        clockingStartLocation: null,
        clockingStopLocation: null,
        clockingType: ClockingType.WORK,
      },
    }
  },
  computed: {
    getFormattedStartTime() {
      return this.workClocking.startTime ? format(this.workClocking.startTime, 'H:mm') : "Geen tijdstip gevonden" 
    },
    getFormattedStopTime() {
      return this.workClocking.endTime ? format(this.workClocking.endTime, 'H:mm') : "Geen tijdstip gevonden" 
    },
    getFormattedDate() {
      return this.applyDateFormat(this.date)
    },
    isOtherUserView() {
      return !!this.$route.params.userId
    },
    getUserName(){
      if (!this.userName?.firstName || !this.userName?.lastName) {
        return ''
      }
      return `${this.userName.firstName} ${this.userName.lastName}`
    },
  },
  methods: {
    getGoogleMapsUrlByCoordinates,
    parseStringToDate,
    applyDateFormat,
    navigateBack() {
      // Default return route
      this.$router.push({
          name: routeNames.DAY,
          params: {date: this.date}
        })
    },
    fetchWorkClocking(){
      if(this.clockingId != null){
        return this.$store.dispatch('clockingsModule/fetchClockingById', this.clockingId).then(result => 
        {
          this.workClocking = {
            ...result,
            startTime: DetailedTimeStamp.fromJson(result?.startTime).getUTCTimeAsDate(),
            endTime: DetailedTimeStamp.fromJson(result?.endTime).getUTCTimeAsDate(),
          }
        })
      } else {
        return null
      }
    },
  },
}
</script>